import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sidenav"
export default class extends Controller {
  static targets = ["opened", "closed"];

  connect() {}

  toggle(e) {
    this.openedTarget.classList.toggle("hidden");
    this.closedTarget.classList.toggle("hidden");
  }
}
